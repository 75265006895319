const cookiesModal = () => {

    //modal elements
    const cookiesForm = document.getElementById('cookies-form');
    const cookieAcceptBtn = cookiesForm.querySelector('[data-cookies-btn="accept"]');
    const cookieDeclineBtn = cookiesForm.querySelector('[data-cookies-btn="decline"]');

    //local storage variables
    const consentPropertyName = 'cookies_enabled';

    const shouldShowModal = () => !localStorage.getItem(consentPropertyName);
    const saveToStorageTrue = () => localStorage.setItem(consentPropertyName, true);
    const saveToStorageFalse = () => localStorage.setItem(consentPropertyName, false);

    //delete all cookies
    if (localStorage.getItem(consentPropertyName) == 'false') {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        return;
    }

    const openModal = () => {
        cookiesForm.classList.remove('dn');
        setTimeout(() => {
            cookiesForm.classList.remove('closed');
        }, 1);
    }

    const closeModal = () => {
        cookiesForm.classList.add('closed');
        setTimeout(() => {
            cookiesForm.classList.add('dn');
        }, 401);
    }

    //show modal logic
    window.addEventListener('load', () => {
        if (shouldShowModal()) {
            openModal();
        }
    });

    //accept btn click
    cookieAcceptBtn.addEventListener('click', () => {
        //saving property name and value to local storage
        saveToStorageTrue();
        closeModal();
    });

    //decline btn click
    cookieDeclineBtn.addEventListener('click', () => {
        //saving property name and value to local storage
        saveToStorageFalse();
        closeModal();
        //reload page
        window.location.reload();
    });


}

export {cookiesModal};