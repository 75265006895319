import Swiper from 'swiper/bundle';

const sliderModule = () => {
    
    if (document.getElementById('steps-slider')) {
        const swiper = new Swiper('#steps-slider', {
            speed: 400,
            centeredSlides: false,
            loop: false,
            initialSlide: 7,
    
            // autoplay: {
            //     delay: 4000,
            //     disableOnInteraction: false
            // },
    
            breakpoints: {
                // when window width is > 0px
                0: {
                    slidesPerView: 1,
                    initialSlide: 0,
                    spaceBetween: 20
                },
                // when window width is > 768px
                769: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                // when window width is > 1200px
                1201: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            },
    
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }

}

export {sliderModule};