import 'idempotent-babel-polyfill';
import LazyLoad from "vanilla-lazyload";
import {cookiesModal} from './modules/cookiesModal';
import {briefForm} from './modules/briefForm';
import {mobileMenu} from './modules/mobileMenu';
import {sliderModule} from './modules/sliderModule';
import {inputMaskPhone} from './modules/inputMaskPhone';

//Including lazy loading
const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});
lazyLoadInstance.update();

//Async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {
  
  //UI modules
  cookiesModal();
  briefForm();
  mobileMenu();
  sliderModule();
  inputMaskPhone();

  sessionStorage.setItem("is_reloaded", true);

});