import intlTelInput from 'intl-tel-input';
import Inputmask from "inputmask";

const inputMaskPhone = () => {

    const phoneInput = document.getElementById("test-phone");

    const input = phoneInput;
    intlTelInput(input, {
        initialCountry: "UA",
        separateDialCode: true
    });
    
    //Inputmask for phone field
    let im = new Inputmask("999-999-999");
    im.mask(phoneInput);

}

export {inputMaskPhone};