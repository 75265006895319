const briefForm = () => {

    const openMainFormBtns = document.querySelectorAll('[data-buttons="open-main-form"]');
    const backToMainPageBtns = document.querySelectorAll('[data-anchors="main-page"]');
    const mainFormBlock = document.querySelector('[data-forms="main"]');
    const menuContainer = document.querySelector('[data-menu="mobile-menu"]');

    const requestModal = document.querySelector('[data-modal="request"]');
    const requestModalWrapper = requestModal.querySelector('[data-modal="text"]');

    const form = document.getElementById('main-form');
    const submitBtnText = form.querySelector('.text');
    const submitBtmSvg = form.querySelector('.svg');
    const submitBtmSvgLoad = form.querySelector('.svg-load');

    //open main form logic
    for (const openMainFormBtn of openMainFormBtns) {
        openMainFormBtn.addEventListener("click", (e) => {
            if(openMainFormBtn.classList.contains('mobile-menu__btn-request')) {
                menuContainer.classList.remove('opened');
                menuContainer.classList.add('dn');
            }
            //close main form block
            mainFormBlock.classList.remove('dn');
            document.body.classList.add('overflow-hidden');
            setTimeout(() => {
                mainFormBlock.classList.add('opened');
            }, 1);
        });
    }

    //back to main page logic
    for (const backToMainPageBtn of backToMainPageBtns) {
        backToMainPageBtn.addEventListener("click", (e) => {
            //close main form block
            mainFormBlock.classList.remove('opened');
            document.body.classList.remove('overflow-hidden');
            setTimeout(() => {
                mainFormBlock.classList.add('dn');
            }, 401);
        });
    }

    //Tests logic
    //=================================================================================================================
    const testForm = document.getElementById('tests-form');

    const startBtn = mainFormBlock.querySelector('[data-main-form="start-btn"]');
    const mainFormWrapper = mainFormBlock.querySelector('[data-main-form="wrapper"]');
    const mainFormBottom = mainFormBlock.querySelector('[data-main-form="bottom"]');

    const testFormBottom = mainFormBlock.querySelector('[data-main-form="bottom-tests"]');
    const mainFormWrapperTests = mainFormBlock.querySelectorAll('[data-main-form="wrapper-test"]');
    const scrollbarForm = mainFormBlock.querySelector('[data-main-form="scrollbar"]');

    const formSendBtn = document.getElementById('form-send-btn');

    //logic for submiting test form
    testForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const phoneNatinality = document.querySelector('.iti__selected-dial-code');

        //change from arrow to loading svg
        nextBtnArrow.classList.add('dn');
        nextBtnLoadSvg.classList.remove('dn');
    
        //Creating FormData object
        const fData = new FormData(testForm);
        fData.append('code', phoneNatinality.innerHTML);
        const fAction = testForm.getAttribute('action');
    
        //Sending form to server
        const fetchResponse = await fetch(fAction, {
            method: 'POST',
            body: fData
        });
    
        const result = await fetchResponse.json();

        //change loading svg to arrow
        nextBtnArrow.classList.remove('dn');
        nextBtnLoadSvg.classList.add('dn');
            
        if (fetchResponse.ok) {
            if (!location.href.includes('ua')) {
                //validation translation
                if (result.message.indexOf('Імя введено некорректно') > -1) {
                    result.message.splice(result.message.indexOf('Імя введено некорректно'), 1);
                    result.message.push('The name was entered incorrectly');
                }
                
                if (result.message.indexOf('Прізвище введено некорректно') > -1) {
                    result.message.splice(result.message.indexOf('Прізвище введено некорректно'), 1);
                    result.message.push('Last name entered incorrectly');
                }
                
                if (result.message.indexOf('Невалідна пошта')  > -1) {
                    result.message.splice(result.message.indexOf('Невалідна пошта'), 1);
                    result.message.push('Invalid mail');
                }
                
                if (result.message.indexOf('Невалідний номер телефону') > -1) {
                    result.message.splice(result.message.indexOf('Невалідний номер телефону'), 1);
                    result.message.push('Invalid phone number');
                }
                
                if (result.message.indexOf('Назва компанії введена некорректно') > -1) {
                    result.message.splice(result.message.indexOf('Назва компанії введена некорректно'), 1);
                    result.message.push('Company name entered incorrectly');
                }
                
                if (result.message.indexOf('Дякуємо за вашу заявку! Найближчим часом з вами зв\'яжуться наші менеджери для подальшої співпраці з вами. Гарного вам дня!') > -1) {
                    result.message = 'Thank you for your application! Our managers will contact you in the near future for further cooperation with you. Have a good day!';
                    testForm.reset();
                }

                //activate modal
                requestModalWrapper.innerHTML = result.message;
                requestModal.classList.remove('dn');

                setTimeout(() => {
                    requestModal.classList.add('opened');
                }, 1);
            } else {
                //activate modal
                requestModalWrapper.innerHTML = result.message;
                requestModal.classList.remove('dn');

                setTimeout(() => {
                    requestModal.classList.add('opened');
                }, 1);

                if (result.message.indexOf('Дякуємо за вашу заявку! Найближчим часом з вами зв\'яжуться наші менеджери для подальшої співпраці з вами. Гарного вам дня!') > -1) {
                    testForm.reset();
                }
            }

        } else {
    
            console.log(result);
    
        }
    });

    //start tests
    startBtn.addEventListener('click', () => {
        window.requestAnimationFrame (() => {
            //close main slide
            mainFormWrapper.classList.add('swiped');
            mainFormBottom.classList.add('swiped');
            setTimeout(() => {
                mainFormWrapper.classList.add('dn');
                mainFormBottom.classList.add('dn');
            }, 401);

            //open test first slide
            mainFormWrapperTests[0].classList.remove('dn');
            setTimeout(() => {
                mainFormWrapperTests[0].classList.remove('hidden');
            }, 1 );
            testFormBottom.classList.remove('dn');
            setTimeout(() => {
                testFormBottom.classList.remove('hidden');
            }, 1);
            scrollbarForm.classList.remove('hidden');
            mainFormBlock.classList.add('overflow-y-auto');
        });
    });

    //other textarea logic
    const openOtherBtns = mainFormBlock.querySelectorAll('[data-opened-textarea-btn]');
    const testTextAreas = mainFormBlock.querySelectorAll('[data-opened-textarea]');
    const scrollerForm = mainFormBlock.querySelector('[data-main-form="scroller"]');

    //add open textare logic to all test other buttons
    for (let i = 0; i < openOtherBtns.length; i++) {
        openOtherBtns[i].addEventListener('change', () => {
            if (openOtherBtns[i].checked) {
                testTextAreas[i].classList.remove('dn');
            } else {
                testTextAreas[i].classList.add('dn');
            }
        });
    }

    //next and previous btns logic
    const nextTestBtn = mainFormBlock.querySelector('[data-main-form="next-test-btn"]');
    const previousTestBtn = mainFormBlock.querySelector('[data-main-form="previous-test-btn"]');
    const nextBtnArrow = nextTestBtn.querySelector('.fa-arrow-right');
    const nextBtnLoadSvg = nextTestBtn.querySelector('.svg-load');
    let s = 0;
    let w = 20;

    nextTestBtn.addEventListener('click', () => {
        window.requestAnimationFrame (() => {
            //hide this wrapper
            if (s != 4) {
                const sOld = s;
                mainFormWrapperTests[sOld].classList.add('swiped');
                setTimeout(() => {
                    mainFormWrapperTests[sOld].classList.add('dn');
                }, 401);
            }

            s++;
            const sNew = s;

            if (s > 3) {
                //change next btn to send
                nextTestBtn.setAttribute('form', 'tests-form');
                nextTestBtn.setAttribute('type', 'submit');

                // if (document.querySelector('.languages__item.active').innerText == 'Eng') {
                    formSendBtn.innerHTML = "Send";
                // } else {
                //     formSendBtn.innerHTML = "Відправити";
                // }
            }

            if (s > 4) {
                //return s last value in global view
                s = 4;
                return s;
            } else {
                //one slide takes 20% of scrollbar
                w = w + 20;
                //show next wrapper
                mainFormWrapperTests[sNew].classList.remove('dn');
                setTimeout(() => {
                    mainFormWrapperTests[sNew].classList.remove('hidden');
                }, 1);
                //add width to scroller
                scrollerForm.style.width = `${w}%`
                //return s value in global view
                return s, w;
            }
        });
    });

    previousTestBtn.addEventListener('click', () => {
        window.requestAnimationFrame (() => {
            if (s >= 3 ) {
                //change send btn to next
                nextTestBtn.setAttribute('form', '');
                nextTestBtn.setAttribute('type', '');
                
                // if (document.querySelector('.languages__item.active').innerText == 'Eng') {
                    formSendBtn.innerHTML = "Next";
                // } else {
                //     formSendBtn.innerHTML = "Далі";
                // }
            }

            //hide this wrapper
            const sOld = s;
            mainFormWrapperTests[sOld].classList.add('hidden');
            setTimeout(() => {
                mainFormWrapperTests[sOld].classList.add('dn');
            }, 401);

            s--;
            const sNew = s;

            //check if its a first wrapper
            if (s < 0) {
                //swipe main slide to view area
                mainFormWrapper.classList.remove('dn');
                mainFormBottom.classList.remove('dn');
                setTimeout(() => {
                    mainFormWrapper.classList.remove('swiped');
                    mainFormBottom.classList.remove('swiped');
                }, 1);
                //hide first slide from view area
                testFormBottom.classList.add('hidden');
                setTimeout(() => {
                    testFormBottom.classList.add('dn');
                }, 401);
                mainFormWrapperTests[0].classList.add('hidden');
                setTimeout(() => {
                    mainFormWrapperTests[0].classList.add('dn');
                }, 401);
                scrollbarForm.classList.add('hidden');
                mainFormBlock.classList.remove('overflow-y-auto');
                //return s default value in global view
                s = 0;
                return s;
            } else {
                //one slide takes 20% of scrollbar
                w = w - 20;
                //show previous wrapper
                mainFormWrapperTests[sNew].classList.remove('dn');
                setTimeout(() => {
                    mainFormWrapperTests[sNew].classList.remove('swiped');
                }, 1);
                //add width to scroller
                scrollerForm.style.width = `${w}%`
                //return s value in global view
                return s, w;
            }
        });
    });

    //upload skip all tests btn logic
    form.addEventListener('click', () => {
        window.requestAnimationFrame (() => {
            //close main slide
            mainFormWrapper.classList.add('swiped');
            mainFormBottom.classList.add('swiped');
            setTimeout(() => {
                mainFormWrapper.classList.add('dn');
                mainFormBottom.classList.add('dn');
            }, 401);

            //open test first last slide and close the others
            for (let i = 0; i < (mainFormWrapperTests.length - 1); i++) {
                mainFormWrapperTests[i].classList.add('swiped');
                setTimeout(() => {
                    mainFormWrapperTests[i].classList.remove('hidden');
                }, 1 );
            }

            //show last slide
            mainFormWrapperTests[4].classList.remove('dn');
            setTimeout(() => {
                mainFormWrapperTests[4].classList.remove('hidden');
            }, 1 );
            testFormBottom.classList.remove('dn');
            setTimeout(() => {
                testFormBottom.classList.remove('hidden');
            }, 1);
            scrollbarForm.classList.remove('hidden');
            mainFormBlock.classList.add('overflow-y-auto');

            //change next btn to send
            nextTestBtn.setAttribute('form', 'tests-form');
            nextTestBtn.setAttribute('type', 'submit');

            //add width to scroller
            scrollerForm.style.width = `100%`

            // if (document.querySelector('.languages__item.active').innerText == 'Eng') {
                formSendBtn.innerHTML = "Send";
            // } else {
            //     formSendBtn.innerHTML = "Відправити";
            // }

            //set last value for s
            s = 4;
            //add width to scroller
            w =  100;
            scrollerForm.style.width = `${w}%`
            return s, w;
        });
    });

    //test brief change name logic
    const testBriefInput = document.getElementById('test-brief');
    const testBriefLabel = document.getElementById('test-brief-label');

    testBriefInput.addEventListener('change', () => {
        testBriefLabel.innerHTML = testBriefInput.files[0].name;
    })

}

export {briefForm};