const mobileMenu = () => {

    //variables
    const menuContainer = document.querySelector('[data-menu="mobile-menu"]');
    const openMenuBtns = document.querySelectorAll('[data-buttons="open-mobile-menu"]');
    const closeMenuBtn = menuContainer.querySelector('[data-buttons="close-mobile-menu"]');

    for (const openMenuBtn of openMenuBtns) {
        //open mobile menu logic
        openMenuBtn.addEventListener('click', () => {
            //hide scroll
            document.body.classList.add('overflow-hidden');
            //open menu
            menuContainer.classList.remove('dn');
            setTimeout(() => {
                menuContainer.classList.add('opened');
            }, 1);
        });
    }

    //open mobile menu logic
    closeMenuBtn.addEventListener('click', () => {
        //show scroll
        document.body.classList.remove('overflow-hidden');
        //close menu
        menuContainer.classList.remove('opened');
        setTimeout(() => {
            menuContainer.classList.add('dn');
        }, 401);
    });
}

export {mobileMenu};